<script setup lang="ts">
import { twMerge } from "tailwind-merge";

const props = defineProps({
  type: {
    type: String as PropType<
      "primary" | "secondary" | "tertiary" | "alternative" | "custom"
    >,
    default: "primary",
  },
  classes: {
    type: String,
    default: "",
  },
  to: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isMenuButton: {
    type: Boolean,
    default: false,
  },
  isSubmit: {
    type: Boolean,
    default: false,
  },
  testId: {
    type: String,
    default: "",
  },
});
defineEmits(["click"]);

const buttonClasses = computed(() => {
  return twMerge(
    "inline-flex items-center w-fit rounded-full py-2.5 px-4 text-sm focus:outline-1 focus:outline-primary gap-x-1 cursor-pointer whitespace-nowrap disabled:cursor-not-allowed border " +
      (props.type === "primary" // primary color
        ? "bg-primary text-white border-black border-opacity-5 hover:bg-primary-hover disabled:bg-primary active:bg-primary"
        : "") +
      (props.type === "secondary" // white color with gray border
        ? "bg-white border-secondary hover:border-secondary-hover disabled:border-secondary active:border-secondary"
        : "") +
      (props.type === "tertiary" // gray color
        ? "bg-tertiary border-transparent hover:bg-tertiary-hover disabled:bg-tertiary active:bg-tertiary"
        : "") +
      (props.type === "alternative" // alternative color light primary
        ? "bg-alternative border-transparent text-primary hover:bg-alternative-hover disabled:bg-alternative active:bg-alternative"
        : "") +
      (props.type === "custom" // custom
        ? "border-transparent"
        : "") +
      " " +
      props.classes
  );
});
</script>

<template>
  <template v-if="props.to !== ''">
    <NuxtLink
      :to="!props.disabled ? props.to : ''"
      :disabled="props.disabled"
      :class="props.type + ' ' + buttonClasses"
      :data-testid="props.testId"
    >
      <slot />
    </NuxtLink>
  </template>
  <template v-else-if="props.isMenuButton">
    <HeadlessMenuButton
      :id="props.testId"
      :class="props.type + ' ' + buttonClasses"
      :disabled="props.disabled"
      @click.prevent="$emit('click')"
    >
      <slot />
    </HeadlessMenuButton>
  </template>
  <template v-else>
    <button
      :type="props.isSubmit ? 'submit' : 'button'"
      :class="props.type + ' ' + buttonClasses"
      :disabled="props.disabled"
      :data-testid="props.testId"
      @click.prevent="$emit('click')"
    >
      <slot />
    </button>
  </template>
</template>

<style scoped>
.primary :slotted(svg) {
  @apply fill-white;
}
.alternative :slotted(svg) {
  @apply fill-primary;
}
</style>
